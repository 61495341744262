<template>
  <div class="staff-update-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="ID">
        <a-input v-model="staff.id" disabled />
      </a-form-item>
      <a-form-item label="姓名">
        <a-input v-model="nameInput" placeholder="请填写姓名" allow-clear />
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model="phoneInput" placeholder="请填写手机号" allow-clear />
      </a-form-item>
      <a-form-item label="身份证号">
        <a-input v-model="idcardInput" placeholder="请填写身份证号" allow-clear />
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-model="remarkInput" placeholder="请填写备注" allow-clear />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import UmsStaffApi from "@/api/ums/ums-staff.js";

export default {
  name: "UserUpdateForm",
  props: ["staff"],
  data() {
    return {
      nameInput: "",
      phoneInput: "",
      idcardInput: "",
      remarkInput: ""
    };
  },
  watch: {
    "staff.id": function(val, oldVal) {
      this.onInit();
    }
  },
  mounted: function() {
    this.onInit();
  },
  methods: {
    onInit: function() {
      if (this.staff) {
        this.nameInput = this.staff.name;
        this.phoneInput = this.staff.phone;
        this.idcardInput = this.staff.idcard;
        this.remarkInput = this.staff.remark;
      }
    },
    onSubmit: function() {
      const payload = {
        id: this.staff.id,
        name: this.nameInput,
        phone: this.phoneInput,
        idcard: this.idcardInput,
        remark: this.remarkInput
      };
      UmsStaffApi.update(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          // Object.assign(this.$data, this.$options.data());
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
