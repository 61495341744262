<template>
  <div class="staff-create-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="姓名">
        <a-input v-model="name" placeholder="请填写姓名" allow-clear/>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model="phone" placeholder="请填写手机号" allow-clear/>
      </a-form-item>
      <a-form-item label="身份证号">
        <a-input v-model="idcard" placeholder="请填写身份证号" allow-clear/>
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-model="remark" placeholder="请填写备注" allow-clear/>
      </a-form-item>
      <a-form-item label="状态">
        <a-radio-group v-model="status">
          <a-radio value="1">启用</a-radio>
          <a-radio value="0">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import UmsStaffApi from "@/api/ums/ums-staff.js";

export default {
  name: "UserCreateForm",
  data() {
    return {
      name: "",
      phone: "",
      idcard: "",
      remark: "",
      status: "0",
    };
  },
  mounted: function() {
  },
  methods: {
    onSubmit: function() {
      const payload = {
        name: this.name,
        phone: this.phone,
        idcard: this.idcard,
        remark: this.remark,
        status: parseInt(this.status)
      };
      UmsStaffApi.create(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          Object.assign(this.$data, this.$options.data());
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
