<template>
  <div class="organization-relate-form">
    <a-form
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
    >
      <a-form-item label="员工姓名">
        <a-input v-model="staff.name" disabled />
      </a-form-item>
      <a-form-item label="组织架构" :help="'上级编号：'+(organizationCurrent.number||'')">
        <a-cascader v-model="organizationCascaderValue"
                    notFoundContent="无数据"
                    :options="organizationTreeList"
                    :field-names="{ label: 'name', value: 'id', children: 'children' }"
                    :disabled="organizationCascaderLoading"
                    change-on-select
                    placeholder="请选择组织架构">
        </a-cascader>
      </a-form-item>
      <a-form-item label="员工编号">
        <a-input v-model="staffNumberInput" placeholder="请填写员工编号" allow-clear />
      </a-form-item>
      <a-form-item label="备注">
        <a-input v-model="remarkInput" placeholder="请填写备注" allow-clear />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" html-type="submit" @click="onSubmit">
          提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import UmsStaffApi from "@/api/ums/ums-staff.js";
import UmsOrganizationApi from "@/api/ums/ums-organization.js";

export default {
  name: "OrganizationRelateForm",
  props: ["staff"],
  data() {
    return {
      organizationTreeList: [],
      organizationCascaderValue: [],
      organizationCascaderLoading: false,
      organizationCurrent: {},
      staffNumberInput: "",
      staffNumberInputErrorMessage: "",
      remarkInput: ""
    };
  },
  watch: {
    "staff.id": function(val, oldVal) {
      this.organizationCascaderValue = [];
      this.staffNumberInput = "";
      this.remarkInput = "";
    },
    organizationCascaderValue: function(val, oldVal) {
      let organizationTree = this.organizationTreeList;
      if (val && val.length > 0) {
        val.forEach(item => {
          organizationTree.forEach(org => {
            if (item === org.id) {
              organizationTree = org.children;
              this.organizationCurrent = org;
            }
          });
        });
      } else {
        this.organizationCurrent = {};
      }
    },
    organizationCurrent: function(val, oldVal) {
      if (val) {
        this.staffNumberInput = val.number;
      } else {
        this.staffNumberInput = "";
      }
    }
  },
  mounted: function() {
    this.onFetchOrganizationTree();
  },
  methods: {
    onFetchOrganizationTree: function() {
      this.organizationCascaderLoading = true;
      UmsOrganizationApi.listTree().then((response) => {
        this.organizationCascaderLoading = false;
        if (response.status === "SUCCESS") {
          this.organizationTreeList = response.body;
        } else {
          this.organizationTreeList = [];
        }
      });
    },
    onLoadOrganizationCascaderValue: function() {
      if (this.staff.relUmsOrganization) {
        const organizationId = this.staff.relUmsOrganization.umsOrganizationId;
        const staffNumber = this.staff.relUmsOrganization.umsStaffNumber;
        let organizationTree = this.organizationTreeList;
        let organizationCurrent = {};
        while (organizationId !== organizationCurrent.id) {
          organizationTree.forEach(org => {
            if (staffNumber.indexOf(org.number + ".") !== -1) {
              this.organizationCascaderValue.push(org.id);
              organizationTree = org.children;
              organizationCurrent = org;
              if (!organizationTree || organizationTree.length === 0) return;
            }
          });
        }
      }
    },
    onCreateRelUmsOrganization: function(payload) {
      UmsStaffApi.createRelUmsOrganization(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          this.onClearData();
        }
      });
    },
    onUpdateRelUmsOrganization: function(payload) {
      UmsStaffApi.updateRelUmsOrganization(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSubmit", response);
          this.onClearData();
        }
      });
    },
    onSubmit: function() {
      if (this.staffNumberInput === this.organizationCurrent.number) {
        this.$message.error("不能以组织架构编号作为员工编号！");
        return;
      }
      if (this.staffNumberInput.indexOf(this.organizationCurrent.number)) {
        this.$message.error("员工编号必须以组织架构编号作为前缀！");
        return;
      }
      const payload = {
        id: this.staff.relUmsOrganization ? this.staff.relUmsOrganization.id : null,
        staffId: this.staff.relUmsOrganization ? null : this.staff.id,
        staffNumber: this.staffNumberInput,
        organizationId: this.organizationCurrent.id,
        remarkInput: this.remarkInput
      };
      if (this.staff.relUmsOrganization) {
        this.onUpdateRelUmsOrganization(payload);
      } else {
        this.onCreateRelUmsOrganization(payload);
      }
    },
    onClearData: function() {
      this.organizationCascaderValue = [];
      this.organizationCurrent = {};
      this.staffNumberInput = "";
      this.remarkInput = "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
