import http from "@/api/http.js";

function getById(id) {
	return http({
		url: "/UmsStaff/GetById/" + id,
		method: "get"
	});
}

function listAll() {
	return http({
		url: "/UmsStaff/ListAll",
		method: "get"
	});
}

function create(payload) {
	return http({
		url: "/UmsStaff/Create",
		method: "post",
		data: payload
	});
}

function update(payload) {
	return http({
		url: "/UmsStaff/Update",
		method: "post",
		data: payload
	});
}

function updateStatus(id) {
	return http({
		url: "/UmsStaff/UpdateStatus/" + id,
		method: "post"
	});
}

function deleteById(id) {
	return http({
		url: "/UmsStaff/DeleteById/" + id,
		method: "post"
	});
}

function getRelUmsOrganizationByUmsStaffId(id) {
	return http({
		url: "/UmsStaff/RelUmsOrganization/GetByUmsStaffId/" + id,
		method: "get"
	});
}

function createRelUmsOrganization(payload) {
	return http({
		url: "/UmsStaff/RelUmsOrganization/Create",
		method: "post",
		data: {
			umsStaffId: payload.staffId,
			umsStaffNumber: payload.staffNumber,
			umsOrganizationId: payload.organizationId,
			remark: payload.remark
		}
	});
}

function updateRelUmsOrganization(payload) {
	return http({
		url: "/UmsStaff/RelUmsOrganization/Update",
		method: "post",
		data: {
			id: payload.id,
			umsStaffNumber: payload.staffNumber,
			umsOrganizationId: payload.organizationId,
			remark: payload.remark
		}
	});
}

function deleteRelUmsOrganizationByRelId(relId) {
	return http({
		url: "/UmsStaff/RelUmsOrganization/DeleteByRelId/" + relId,
		method: "post",
	});
}

export default {
	getById,
	listAll,
	create,
	update,
	updateStatus,
	deleteById,
	getRelUmsOrganizationByUmsStaffId,
	createRelUmsOrganization,
	updateRelUmsOrganization,
	deleteRelUmsOrganizationByRelId
};
