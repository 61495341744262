<template>
	<div class="ums-staff">
		<div class="header">
			<a-breadcrumb style="margin-bottom: 20px">
				<a-breadcrumb-item>用户中心</a-breadcrumb-item>
				<a-breadcrumb-item>员工信息</a-breadcrumb-item>
			</a-breadcrumb>
			<a-row>
				<a-col :span="18">
					<a-button type="primary" @click="staffCreateModalVisible = true">新增</a-button>
				</a-col>
				<a-col :span="6">
					<a-input-search v-model="dataSourceSearchInput" placeholder="请输入任何想搜索的内容" allowClear
						@search="handleDataSourceSearch" />
				</a-col>
			</a-row>
		</div>
		<div class="body">
			<a-table rowKey="id" size="small" bordered :data-source="getCurrentDataSourceList"
				:pagination="paginationOptions" :scroll="{ x: true }" :loading="dataSourceTableLoading"
				@change="(pagination) => {paginationOptions.current = pagination.current;paginationOptions.pageSize = pagination.pageSize;}">
				<a-table-column key="index" title="序号" data-index="index" align="center" fixed="left" :width="60">
					<template slot-scope="text, record, index">
						<span>{{(paginationOptions.current - 1) * paginationOptions.pageSize + parseInt(index) + 1}}</span>
					</template>
				</a-table-column>
				<a-table-column key="name" title="姓名" data-index="name" fixed="left" :width="100" />
				<a-table-column key="phone" title="手机号" :width="200" data-index="phone" />
				<a-table-column key="idcard" title="身份证" :width="200" data-index="idcard" />
				<a-table-column key="staffNumber" title="编号" :width="200" data-index="index">
					<template slot-scope="text, record">
						<span v-if="record.relUmsOrganization">{{ record.relUmsOrganization.umsStaffNumber }}</span>
					</template>
				</a-table-column>
				<a-table-column key="fullName" title="岗位" :width="300" data-index="index">
					<template slot-scope="text, record">
						<span
							v-if="record.relUmsOrganization">{{ record.relUmsOrganization.umsOrganizationFullName }}</span>
					</template>
				</a-table-column>
				<a-table-column key="remark" title="备注" data-index="remark" :width="100" />
				<a-table-column key="status" title="状态" :width="100" data-index="status" fixed="right">
					<template slot-scope="text, record">
						<a-switch checked-children="启用" un-checked-children="禁用" :checked="text === 1"
							@change="handleStatusSwitchChange(record)" />
					</template>
				</a-table-column>
				<a-table-column title="操作" data-index="operation" fixed="right">
					<template slot-scope="text, record">
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;staffUpdateModalVisible = true;">资料修改
						</a-button>
						<a-button type="link" size="small"
							@click="dataSourceCurrent = record;organizationRelateModalVisible = true;">岗位调整
						</a-button>
						<a-popconfirm title="是否重置此员工岗位？" ok-text="是" cancel-text="否"
							@confirm="handleOrganizationReset(record)">
							<a-button type="link" size="small">岗位重置</a-button>
						</a-popconfirm>
						<a-popconfirm title="是否删除此员工信息？" ok-text="是" cancel-text="否"
							@confirm="handleDataSourceDelete(record)">
							<a-button type="link" size="small">删除</a-button>
						</a-popconfirm>
					</template>
				</a-table-column>
			</a-table>
		</div>
		<div class="footer"></div>

		<a-modal v-model="staffCreateModalVisible" title="请填写以下信息" closable :footer="null">
			<staff-create-form @onSubmit="handleStaffCreateFormSubmit" />
		</a-modal>

		<a-modal v-model="staffUpdateModalVisible" title="请填写以下信息" closable :footer="null">
			<staff-update-form :staff="dataSourceCurrent" @onSubmit="handleStaffUpdateFormSubmit" />
		</a-modal>

		<a-modal v-model="organizationRelateModalVisible" title="请填写以下信息" closable :footer="null">
			<organization-relate-form :staff="dataSourceCurrent" @onSubmit="handleOrganizationRelateFormSubmit" />
		</a-modal>

	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import UmsStaffApi from "@/api/ums/ums-staff.js";
	import StaffCreateForm from "./components/StaffCreateForm.vue";
	import StaffUpdateForm from "./components/StaffUpdateForm.vue";
	import OrganizationRelateForm from "./components/OrganizationRelateForm.vue";

	export default {
		components: {
			StaffCreateForm,
			StaffUpdateForm,
			OrganizationRelateForm
		},
		name: "UmsStaff",
		data() {
			return {
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				queryType: "1",
				queryDate: [],
				dataSourceList: [],
				dataSourceCurrent: {},
				dataSourceSearchInput: "",
				dataSourceSearchList: [],
				dataSourceSearchValid: false,
				dataSourceTableLoading: false,
				staffCreateModalVisible: false,
				staffUpdateModalVisible: false,
				organizationRelateModalVisible: false
			};
		},
		computed: {
			getCurrentDataSourceList: function() {
				return this.dataSourceSearchValid ?
					this.dataSourceSearchList :
					this.dataSourceList;
			}
		},
		watch: {
			dataSourceSearchInput: function(val) {
				if (val === null || val === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
				}
			}
		},
		mounted: function() {
			this.fetchStaffList();
		},
		methods: {
			fetchStaffAndUpdate: function(staffId) {
				UmsStaffApi.getById(staffId).then((response) => {
					if (response.status === "SUCCESS" && response.body) {
						for (let i = 0, j = this.dataSourceList.length; i < j; i++) {
							if (this.dataSourceList[i].id === response.body.id) {
								this.$set(this.dataSourceList, i, response.body);
								if (this.dataSourceSearchInput) {
									this.handleDataSourceSearch(this.dataSourceSearchInput);
								}
							}
						}
					} else {
						this.$message.error("获取此员工信息失败！");
					}
				});
			},
			fetchStaffList: function() {
				this.dataSourceTableLoading = true;
				UmsStaffApi.listAll().then((response) => {
					this.dataSourceTableLoading = false;
					if (response.status === "SUCCESS") {
						this.dataSourceList = response.body;
						if (this.dataSourceList.length === 0) this.$message.success("无数据");
					} else {
						this.dataSourceList = [];
					}
				});
			},
			handleTableChange(pagination) {
				this.paginationOptions.current = pagination.current;
				this.paginationOptions.pageSize = pagination.pageSize;
			},
			handleDataSourceSearch: function(value) {
				if (value === null || value === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
					return;
				}
				this.dataSourceSearchList = this.dataSourceList.filter((item) => {
					return JSON.stringify(item).indexOf(value.trim()) !== -1;
				});
				this.dataSourceSearchValid = true;
			},
			handleStatusSwitchChange: function(record) {
				UmsStaffApi.updateStatus(record.id).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("更新成功");
						this.fetchStaffAndUpdate(record.id);
					}
				});
			},
			handleStaffCreateFormSubmit: function(response) {
				this.staffCreateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchStaffList();
				}
			},
			handleStaffUpdateFormSubmit: function(response) {
				this.staffUpdateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchStaffAndUpdate(this.dataSourceCurrent.id);
				}
			},
			handleOrganizationRelateFormSubmit: function(response) {
				this.organizationRelateModalVisible = false;
				if (response && response.status === "SUCCESS") {
					this.fetchStaffAndUpdate(this.dataSourceCurrent.id);
				}
			},
			handleOrganizationReset: function(record) {
				UmsStaffApi.deleteRelUmsOrganizationByRelId(record.relUmsOrganization.id).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("岗位重置成功！");
						this.fetchStaffAndUpdate(record.id);
					}
				});
			},
			handleDataSourceDelete: function(record) {
				UmsStaffApi.deleteById(record.id).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success("员工信息删除成功！");
						this.fetchStaffList();
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
